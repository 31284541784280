export enum LOCATION_REGION {
    EMEA = 'EMEA',
    NCSA = 'NCSA',
    MENA = 'MENA',
    ANZ = 'ANZ',
    EE = 'easternEurope',
    CN = 'china',
    RU = 'russia',
    JP = 'japan'
}

export interface ICountry {
    code: string;
    name: string;
    region?: string;
    subregion?: string;
}

export enum COUNTRY {
    // --- NCSA Region ---
    AI = 'Anguilla',
    AG = 'Antigua and Barbuda',
    AR = 'Argentina',
    AW = 'Aruba',
    BS = 'Bahamas',
    BB = 'Barbados',
    BZ = 'Belize',
    BM = 'Bermuda',
    BO = 'Bolivia (Plurinational State of)',
    BQ = 'Bonaire, Sint Eustatius and Saba',
    BR = 'Brazil',
    CA = 'Canada',
    KY = 'Cayman Islands',
    CL = 'Chile',
    CO = 'Colombia',
    CR = 'Costa Rica',
    CU = 'Cuba',
    CW = 'Curaçao',
    DM = 'Dominica',
    DO = 'Dominican Republic',
    EC = 'Ecuador',
    SV = 'El Salvador',
    GF = 'French Guiana',
    GD = 'Grenada',
    GP = 'Guadeloupe',
    GT = 'Guatemala',
    GY = 'Guyana',
    HT = 'Haiti',
    HN = 'Honduras',
    JM = 'Jamaica',
    MQ = 'Martinique',
    MX = 'Mexico',
    MS = 'Montserrat',
    NI = 'Nicaragua',
    PA = 'Panama',
    PY = 'Paraguay',
    PE = 'Peru',
    PR = 'Puerto Rico',
    BL = 'Saint Barthélemy',
    KN = 'Saint Kitts And Nevis',
    LC = 'Saint Lucia',
    MF = 'Saint Martin',
    PM = 'Saint Pierre and Miquelon',
    VC = 'Saint Vincent and The Grenadines',
    SX = 'Sint Maarten (Dutch part)',
    SR = 'Suriname',
    TT = 'Trinidad and Tobago',
    TC = 'Turks and Caicos Islands',
    US = 'United States',
    UY = 'Uruguay',
    VE = 'Venezuela',
    VG = 'Virgin Islands (British)',
    VI = 'Virgin Islands (U.S.)',
    // --- EMEA Region ---
    AF = 'Afghanistan',
    AX = 'Åland Islands',
    AL = 'Albania',
    DZ = 'Algeria',
    AD = 'Andorra',
    AO = 'Angola',
    AM = 'Armenia',
    AT = 'Austria',
    AZ = 'Azerbaijan',
    BH = 'Bahrain',
    BY = 'Belarus',
    BE = 'Belgium',
    BJ = 'Benin',
    BA = 'Bosnia and Herzegovina',
    BW = 'Botswana',
    BV = 'Bouvet Island',
    BG = 'Bulgaria',
    BF = 'Burkina Faso',
    BI = 'Burundi',
    CM = 'Cameroon',
    CV = 'Cabo Verde',
    CF = 'Central African Republic',
    TD = 'Chad',
    KM = 'Comoros',
    CD = 'Congo (Democratic Republic of the)',
    CG = 'Congo',
    CI = 'Côte d’Ivoire',
    HR = 'Croatia',
    CY = 'Cyprus',
    CZ = 'Czech Republic',
    DK = 'Denmark',
    DJ = 'Djibouti',
    EG = 'Egypt',
    GQ = 'Equatorial Guinea',
    ER = 'Eritrea',
    EE = 'Estonia',
    ET = 'Ethiopia',
    FK = 'Falkland Islands',
    FO = 'Faroe Islands',
    FI = 'Finland',
    FR = 'France',
    GA = 'Gabon',
    GM = 'Gambia',
    GE = 'Georgia',
    DE = 'Germany',
    GH = 'Ghana',
    GI = 'Gibraltar',
    GR = 'Greece',
    GL = 'Greenland',
    GG = 'Guernsey',
    GN = 'Guinea',
    GW = 'Guinea-Bissau',
    VA = 'Vatican City',
    HU = 'Hungary',
    IS = 'Iceland',
    IR = 'Iran',
    IQ = 'Iraq',
    IE = 'Ireland',
    IM = 'Isle of Man',
    IL = 'Israel',
    IT = 'Italy',
    JE = 'Jersey',
    JP = 'Japan',
    JO = 'Jordan',
    KZ = 'Kazakhstan',
    KE = 'Kenya',
    KV = 'Korea (Democratic People’s Republic of)',
    KR = 'Korea (Republic of)',
    KW = 'Kuwait',
    KG = 'Kyrgyzstan',
    LV = 'Latvia',
    LB = 'Lebanon',
    LS = 'Lesotho',
    LR = 'Liberia',
    LY = 'Libya',
    LI = 'Liechtenstein',
    LT = 'Lithuania',
    LU = 'Luxembourg',
    MK = 'Macedonia',
    MG = 'Madagascar',
    MW = 'Malawi',
    ML = 'Mali',
    MT = 'Malta',
    MR = 'Mauritania',
    MU = 'Mauritius',
    YT = 'Mayotte',
    MD = 'Moldova',
    MC = 'Monaco',
    ME = 'Montenegro',
    MA = 'Morocco',
    MZ = 'Mozambique',
    NA = 'Namibia',
    NL = 'Netherlands',
    NE = 'Niger',
    NG = 'Nigeria',
    NO = 'Norway',
    OM = 'Oman',
    PS = 'Palestine',
    PL = 'Poland',
    PT = 'Portugal',
    QA = 'Qatar',
    RE = 'Réunion',
    RO = 'Romania',
    RU = 'Russia',
    RW = 'Rwanda',
    SH = 'Saint Helena',
    SM = 'San Marino',
    ST = 'Sao Tome and Principe',
    SA = 'Saudi Arabia',
    SN = 'Senegal',
    RS = 'Serbia',
    SC = 'Seychelles',
    SL = 'Sierra Leone',
    SK = 'Slovakia',
    SI = 'Slovenia',
    SO = 'Somalia',
    ZA = 'South Africa',
    GS = 'South Georgia and the South Sandwich Islands',
    ES = 'Spain',
    SD = 'Sudan',
    SJ = 'Svalbard',
    SZ = 'Swaziland',
    SE = 'Sweden',
    CH = 'Switzerland',
    SY = 'Syria',
    TZ = 'Tanzania',
    TG = 'Togo',
    TN = 'Tunisia',
    TR = 'Turkey',
    TW = 'Taiwan',
    TJ = 'Tajikistan',
    TH = 'Thailand',
    TM = 'Turkmenistan',
    UG = 'Uganda',
    UZ = 'Uzbekistan',
    UA = 'Ukraine',
    AE = 'United Arab Emirates',
    GB = 'United Kingdom',
    EH = 'Western Sahara',
    YE = 'Yemen',
    ZM = 'Zambia',
    ZW = 'Zimbabwe',
    // --- ANZ (Australia + New Zealand) Region ---
    AU = 'Australia',
    NZ = 'New Zealand',
    AS = 'American Samoa',
    CK = 'Cook Islands',
    FJ = 'Fiji',
    FM = 'Micronesia, Federal States of',
    GU = 'Guam',
    KI = 'Kiribati',
    MH = 'Marshall Islands',
    MP = 'Northern Mariana Islands',
    NC = 'New Caledonia',
    NF = 'Norfolk Island',
    NR = 'Nauru',
    NU = 'Niue',
    PF = 'French Polynesia',
    PG = 'Papua New Guinea',
    PN = 'Pitcairn',
    PW = 'Palau',
    SB = 'Solomon Islands',
    TK = 'Tokelau',
    TO = 'Tonga',
    TV = 'Tuvalu',
    UM = 'United States Minor Outlying Islands',
    VU = 'Vanuatu',
    WF = 'Wallis and Futuna',
    WS = 'Samoa',
    // --- CN (China) Region ---
    CN = 'China',
}

export const COUNTRIES: ICountry[] = [
    { name: 'Afghanistan', code: 'AF', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Åland Islands', code: 'AX', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Albania', code: 'AL', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Algeria', code: 'DZ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Andorra', code: 'AD', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Angola', code: 'AO', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Armenia', code: 'AM', subregion: '', region: LOCATION_REGION.EE },
    { name: 'Austria', code: 'AT', subregion: 'Central-West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Australia', code: 'AU', subregion: '' },
    { name: 'Azerbaijan', code: 'AZ', subregion: '', region: LOCATION_REGION.EE },
    { name: 'Bahrain', code: 'BH', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Belarus', code: 'BY', subregion: '', region: LOCATION_REGION.EE },
    { name: 'Belgium', code: 'BE', subregion: 'West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Benin', code: 'BJ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Bosnia and Herzegovina', code: 'BA', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Botswana', code: 'BW', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Bouvet Island', code: 'BV', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Bulgaria', code: 'BG', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Burkina Faso', code: 'BF', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Burundi', code: 'BI', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Cameroon', code: 'CM', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Cabo Verde', code: 'CV', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Central African Republic', code: 'CF', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Chad', code: 'TD', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'China', code: 'CN', subregion: '', region: LOCATION_REGION.CN },
    { name: 'Comoros', code: 'KM', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Congo (Democratic Republic of the)', code: 'CD', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Congo', code: 'CG', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Côte d’Ivoire', code: 'CI', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Croatia', code: 'HR', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Cyprus', code: 'CY', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Czech Republic', code: 'CZ', subregion: 'Central-East Europe', region: LOCATION_REGION.EMEA },
    { name: 'Denmark', code: 'DK', subregion: 'North West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Djibouti', code: 'DJ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Egypt', code: 'EG', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Equatorial Guinea', code: 'GQ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Eritrea', code: 'ER', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Estonia', code: 'EE', subregion: 'North East Europe', region: LOCATION_REGION.EMEA },
    { name: 'Ethiopia', code: 'ET', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Falkland Islands', code: 'FK', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Faroe Islands', code: 'FO', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Finland', code: 'FI', subregion: 'North West Europe', region: LOCATION_REGION.EMEA },
    { name: 'France', code: 'FR', subregion: 'West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Gabon', code: 'GA', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Gambia', code: 'GM', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Georgia', code: 'GE', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Germany', code: 'DE', subregion: 'Central-West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Ghana', code: 'GH', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Gibraltar', code: 'GI', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Greece', code: 'GR', subregion: 'South West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Greenland', code: 'GL', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Guernsey', code: 'GG', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Guinea', code: 'GN', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Guinea-Bissau', code: 'GW', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Vatican City', code: 'VA', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Hungary', code: 'HU', subregion: 'Central-East Europe', region: LOCATION_REGION.EMEA },
    { name: 'Iceland', code: 'IS', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Iran', code: 'IR', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Iraq', code: 'IQ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Ireland', code: 'IE', subregion: 'West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Isle of Man', code: 'IM', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Israel', code: 'IL', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Italy', code: 'IT', subregion: 'South West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Jersey', code: 'JE', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Jordan', code: 'JO', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Japan', code: 'JP', subregion: '', region: LOCATION_REGION.JP },
    { name: 'Kazakhstan', code: 'KZ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Kenya', code: 'KE', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Korea (Democratic People’s Republic of)', code: 'KV', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Korea (Republic of)', code: 'KR', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Kuwait', code: 'KW', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Kyrgyzstan', code: 'KG', subregion: '', region: LOCATION_REGION.EE },
    { name: 'Latvia', code: 'LV', subregion: 'North East Europe', region: LOCATION_REGION.EMEA },
    { name: 'Lebanon', code: 'LB', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Lesotho', code: 'LS', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Liberia', code: 'LR', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Libya', code: 'LY', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Liechtenstein', code: 'LI', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Lithuania', code: 'LT', subregion: 'North East Europe', region: LOCATION_REGION.EMEA },
    { name: 'Luxembourg', code: 'LU', subregion: 'Central-West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Macedonia', code: 'MK', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Madagascar', code: 'MG', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Malawi', code: 'MW', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Mali', code: 'ML', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Malta', code: 'MT', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Mauritania', code: 'MR', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Mauritius', code: 'MU', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Mayotte', code: 'YT', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Moldova', code: 'MD', subregion: '', region: LOCATION_REGION.EE },
    { name: 'Monaco', code: 'MC', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Montenegro', code: 'ME', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Morocco', code: 'MA', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Mozambique', code: 'MZ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Namibia', code: 'NA', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Netherlands', code: 'NL', subregion: 'West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Niger', code: 'NE', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Nigeria', code: 'NG', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Norway', code: 'NO', subregion: 'North West Europe', region: LOCATION_REGION.EMEA },
    { name: 'New Zealand', code: 'NZ', subregion: '' },
    { name: 'Oman', code: 'OM', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Palestine', code: 'PS', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Poland', code: 'PL', subregion: 'Central-East Europe', region: LOCATION_REGION.EMEA },
    { name: 'Portugal', code: 'PT', subregion: 'South West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Qatar', code: 'QA', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Réunion', code: 'RE', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Romania', code: 'RO', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Russia', code: 'RU', subregion: '', region: LOCATION_REGION.RU },
    { name: 'Rwanda', code: 'RW', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Saint Helena', code: 'SH', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'San Marino', code: 'SM', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Sao Tome and Principe', code: 'ST', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Saudi Arabia', code: 'SA', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Senegal', code: 'SN', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Serbia', code: 'RS', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Seychelles', code: 'SC', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Sierra Leone', code: 'SL', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Slovakia', code: 'SK', subregion: 'Central-East Europe', region: LOCATION_REGION.EMEA },
    { name: 'Slovenia', code: 'SI', subregion: 'Central-East Europe', region: LOCATION_REGION.EMEA },
    { name: 'Somalia', code: 'SO', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'South Africa', code: 'ZA', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Spain', code: 'ES', subregion: 'South West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Sudan', code: 'SD', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Svalbard', code: 'SJ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Swaziland', code: 'SZ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Sweden', code: 'SE', subregion: 'North West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Switzerland', code: 'CH', subregion: 'Central-West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Syria', code: 'SY', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Tanzania', code: 'TZ', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Taiwan', code: 'TW', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Togo', code: 'TG', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Tunisia', code: 'TN', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Turkey', code: 'TR', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Thailand', code: 'TH', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Tajikistan', code: 'TJ', subregion: '', region: LOCATION_REGION.EE },
    { name: 'Turkmenistan', code: 'TM', subregion: '', region: LOCATION_REGION.EE },
    { name: 'Uganda', code: 'UG', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Ukraine', code: 'UA', subregion: '', region: LOCATION_REGION.EE },
    { name: 'Uzbekistan', code: 'UZ', subregion: '', region: LOCATION_REGION.EE },
    { name: 'United Arab Emirates', code: 'AE', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'United Kingdom', code: 'GB', subregion: 'West Europe', region: LOCATION_REGION.EMEA },
    { name: 'Western Sahara', code: 'EH', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Yemen', code: 'YE', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Zambia', code: 'ZM', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'Zimbabwe', code: 'ZW', subregion: '', region: LOCATION_REGION.EMEA },
    { name: 'American Samoa', code: 'AS', subregion: '' },
    { name: 'Cook Islands', code: 'CK', subregion: '' },
    { name: 'Fiji', code: 'FJ', subregion: '' },
    { name: 'Micronesia, Federal States of', code: 'FM', subregion: '' },
    { name: 'Guam', code: 'GU', subregion: '' },
    { name: 'Kiribati', code: 'KI', subregion: '' },
    { name: 'Marshall Islands', code: 'MH', subregion: '' },
    { name: 'Northern Mariana Islands', code: 'MP', subregion: '' },
    { name: 'New Caledonia', code: 'NC', subregion: '' },
    { name: 'Norfolk Island', code: 'NF', subregion: '' },
    { name: 'Nauru', code: 'NR', subregion: '' },
    { name: 'Niue', code: 'NU', subregion: '' },
    { name: 'French Polynesia', code: 'PF', subregion: '' },
    { name: 'Papua New Guinea', code: 'PG', subregion: '' },
    { name: 'Pitcairn', code: 'PN', subregion: '' },
    { name: 'Palau', code: 'PW', subregion: '' },
    { name: 'Solomon Islands', code: 'SB', subregion: '' },
    { name: 'Tokelau', code: 'TK', subregion: '' },
    { name: 'Tonga', code: 'TO', subregion: '' },
    { name: 'Tuvalu', code: 'TV', subregion: '' },
    { name: 'United States Minor Outlying Islands', code: 'UM', subregion: '' },
    { name: 'Vanuatu', code: 'VU', subregion: '' },
    { name: 'Wallis and Futuna', code: 'WF', subregion: '' },
    { name: 'Samoa', code: 'WS', subregion: '' },
    { name: 'United States', code: 'US', subregion: LOCATION_REGION.NCSA },
    { name: 'Canada', code: 'CA', subregion: LOCATION_REGION.NCSA },
    { name: 'Brazil', code: 'BR', subregion: LOCATION_REGION.NCSA },
    { name: 'Mexico', code: 'MX', subregion: LOCATION_REGION.NCSA }
];

export const COUNTRIES_BY_REGION: { [key: string]: COUNTRY[] } = {
    [LOCATION_REGION.NCSA]: [
        COUNTRY.AI,
        COUNTRY.AG,
        COUNTRY.AR,
        COUNTRY.AW,
        COUNTRY.BS,
        COUNTRY.BB,
        COUNTRY.BZ,
        COUNTRY.BM,
        COUNTRY.BO,
        COUNTRY.BQ,
        COUNTRY.BR,
        COUNTRY.CA,
        COUNTRY.KY,
        COUNTRY.CL,
        COUNTRY.CO,
        COUNTRY.CR,
        COUNTRY.CU,
        COUNTRY.CW,
        COUNTRY.DM,
        COUNTRY.DO,
        COUNTRY.EC,
        COUNTRY.SV,
        COUNTRY.GF,
        COUNTRY.GD,
        COUNTRY.GP,
        COUNTRY.GT,
        COUNTRY.GY,
        COUNTRY.HT,
        COUNTRY.HN,
        COUNTRY.JM,
        COUNTRY.MQ,
        COUNTRY.MX,
        COUNTRY.MS,
        COUNTRY.NI,
        COUNTRY.PA,
        COUNTRY.PY,
        COUNTRY.PE,
        COUNTRY.PR,
        COUNTRY.BL,
        COUNTRY.KN,
        COUNTRY.LC,
        COUNTRY.MF,
        COUNTRY.PM,
        COUNTRY.VC,
        COUNTRY.SX,
        COUNTRY.SR,
        COUNTRY.TT,
        COUNTRY.TC,
        COUNTRY.US,
        COUNTRY.UY,
        COUNTRY.VE,
        COUNTRY.VG,
        COUNTRY.VI,
    ],
    [LOCATION_REGION.EMEA]: [
        COUNTRY.AF,
        COUNTRY.AX,
        COUNTRY.AL,
        COUNTRY.DZ,
        COUNTRY.AD,
        COUNTRY.AO,
        COUNTRY.AT,
        COUNTRY.BH,
        COUNTRY.BE,
        COUNTRY.BJ,
        COUNTRY.BA,
        COUNTRY.BW,
        COUNTRY.BV,
        COUNTRY.BG,
        COUNTRY.BF,
        COUNTRY.BI,
        COUNTRY.CM,
        COUNTRY.CV,
        COUNTRY.CF,
        COUNTRY.TD,
        COUNTRY.KM,
        COUNTRY.CD,
        COUNTRY.CG,
        COUNTRY.CI,
        COUNTRY.HR,
        COUNTRY.CY,
        COUNTRY.CZ,
        COUNTRY.DK,
        COUNTRY.DJ,
        COUNTRY.EG,
        COUNTRY.GQ,
        COUNTRY.ER,
        COUNTRY.EE,
        COUNTRY.ET,
        COUNTRY.FK,
        COUNTRY.FO,
        COUNTRY.FI,
        COUNTRY.FR,
        COUNTRY.GA,
        COUNTRY.GM,
        COUNTRY.GE,
        COUNTRY.DE,
        COUNTRY.GH,
        COUNTRY.GI,
        COUNTRY.GR,
        COUNTRY.GL,
        COUNTRY.GG,
        COUNTRY.GN,
        COUNTRY.GW,
        COUNTRY.VA,
        COUNTRY.HU,
        COUNTRY.IS,
        COUNTRY.IR,
        COUNTRY.IQ,
        COUNTRY.IE,
        COUNTRY.IM,
        COUNTRY.IL,
        COUNTRY.IT,
        COUNTRY.JE,
        COUNTRY.JO,
        COUNTRY.KZ,
        COUNTRY.KE,
        COUNTRY.KV,
        COUNTRY.KR,
        COUNTRY.KW,
        COUNTRY.LV,
        COUNTRY.LB,
        COUNTRY.LS,
        COUNTRY.LR,
        COUNTRY.LY,
        COUNTRY.LI,
        COUNTRY.LT,
        COUNTRY.LU,
        COUNTRY.MK,
        COUNTRY.MG,
        COUNTRY.MW,
        COUNTRY.ML,
        COUNTRY.MT,
        COUNTRY.MR,
        COUNTRY.MU,
        COUNTRY.YT,
        COUNTRY.MC,
        COUNTRY.ME,
        COUNTRY.MA,
        COUNTRY.MZ,
        COUNTRY.NA,
        COUNTRY.NL,
        COUNTRY.NE,
        COUNTRY.NG,
        COUNTRY.NO,
        COUNTRY.OM,
        COUNTRY.PS,
        COUNTRY.PL,
        COUNTRY.PT,
        COUNTRY.QA,
        COUNTRY.RE,
        COUNTRY.RO,
        COUNTRY.RW,
        COUNTRY.SH,
        COUNTRY.SM,
        COUNTRY.ST,
        COUNTRY.SA,
        COUNTRY.SN,
        COUNTRY.RS,
        COUNTRY.SC,
        COUNTRY.SL,
        COUNTRY.SK,
        COUNTRY.SI,
        COUNTRY.SO,
        COUNTRY.ZA,
        COUNTRY.GS,
        COUNTRY.ES,
        COUNTRY.SD,
        COUNTRY.SJ,
        COUNTRY.SZ,
        COUNTRY.SE,
        COUNTRY.CH,
        COUNTRY.SY,
        COUNTRY.TZ,
        COUNTRY.TG,
        COUNTRY.TN,
        COUNTRY.TR,
        COUNTRY.TH,
        COUNTRY.UG,
        COUNTRY.AE,
        COUNTRY.GB,
        COUNTRY.EH,
        COUNTRY.YE,
        COUNTRY.ZM,
        COUNTRY.ZW,
    ],
    [LOCATION_REGION.MENA]: [
        COUNTRY.BH,
        COUNTRY.CY,
        COUNTRY.EG,
        COUNTRY.IR,
        COUNTRY.IQ,
        COUNTRY.IL,
        COUNTRY.JO,
        COUNTRY.KW,
        COUNTRY.LB,
        COUNTRY.OM,
    ],
    [LOCATION_REGION.ANZ]: [
        COUNTRY.AS,
        COUNTRY.AU,
        COUNTRY.CK,
        COUNTRY.FJ,
        COUNTRY.FM,
        COUNTRY.GU,
        COUNTRY.KI,
        COUNTRY.MH,
        COUNTRY.MP,
        COUNTRY.NC,
        COUNTRY.NF,
        COUNTRY.NR,
        COUNTRY.NU,
        COUNTRY.NZ,
        COUNTRY.PF,
        COUNTRY.PG,
        COUNTRY.PN,
        COUNTRY.PW,
        COUNTRY.SB,
        COUNTRY.TK,
        COUNTRY.TO,
        COUNTRY.TV,
        COUNTRY.UM,
        COUNTRY.VU,
        COUNTRY.WF,
        COUNTRY.WS
    ],
    [LOCATION_REGION.EE]: [
        COUNTRY.UA,
        COUNTRY.AM,
        COUNTRY.AZ,
        COUNTRY.BY,
        COUNTRY.KG,
        COUNTRY.MD,
        COUNTRY.TJ,
        COUNTRY.TM,
        COUNTRY.UZ
    ],
    [LOCATION_REGION.JP]: [COUNTRY.JP],
    [LOCATION_REGION.RU]: [COUNTRY.RU],
    [LOCATION_REGION.CN]: [COUNTRY.CN],
};

export const ANZ_COUNTRIES_CODES = [
    'AU', 'NZ', 'AS', 'CK', 'FJ',
    'FM', 'GU', 'KI', 'MH', 'MP',
    'NC', 'NF', 'NR', 'NU', 'PF',
    'PG', 'PN', 'PW', 'SB', 'TK',
    'TO', 'TV', 'UM', 'VU', 'WF', 'WS'
];

export const EE_COUNTRIES_CODES = [
    'UA', 'AM', 'AZ', 'BY', 'KG',
    'MD', 'TJ', 'TM', 'UZ'
];

import { IWebsiteSubdomain } from '../types';
import { ENV } from './environments';

export const REGISTER_DOMAIN = 'register';
export const FREE_WEEKEND_DOMAIN = 'freeweekend';
export const TWITCH_PRIME_DOMAIN = 'twitchprime';
export const REDEEM_DOMAIN = 'redeem';
export const PC_PROMO = 'pc-promo';
export const STANDARD_REGISTRATION_DOMAIN = 'standard-registration';
export const FRIENDS_REFERRAL_DOMAIN = 'referral';

export type IWebsiteDomain = typeof REGISTER_DOMAIN
    | typeof FREE_WEEKEND_DOMAIN
    | typeof TWITCH_PRIME_DOMAIN
    | typeof REDEEM_DOMAIN
    | typeof PC_PROMO
    | typeof STANDARD_REGISTRATION_DOMAIN
    | typeof FRIENDS_REFERRAL_DOMAIN;

export const formWebsiteSubdomain = (subdomain: IWebsiteDomain): IWebsiteSubdomain => ({
    [ENV.DEV]: `https://dev-${subdomain}.ubisoft.com`,
    [ENV.QC]: `https://qc-${subdomain}.ubisoft.com`,
    [ENV.UAT]: `https://uat-${subdomain}.ubisoft.com`,
    [ENV.PREPROD]: `https://preprod-${subdomain}.ubisoft.com`,
    [ENV.PROD]: `https://${subdomain}.ubisoft.com`
});

export const DEFAULT_CDN_URL = 'https://static-webservicesops.akamaized.net/';

export const formWebsiteCDNUrl = ({ env, flow = null }: { env: ENV, flow?: IWebsiteDomain | null}): string => {
    let result = `${DEFAULT_CDN_URL}sitegen/${env}/FO/`;
    if (flow) {
        result = `${result}${flow}`;
    }
    return result;
};
